import { createDirectStore } from "direct-vuex";
import { ExponeaService } from "@/services/tracking/exponea.service";
import GTMService from "@/services/tracking/gtm.service";
import SimTariffService from "@/services/sim-tariffs/SimTariffService";
import WebApiService from "@/services/webapi/WebApiService";
import { UtmService } from "@/services/utm.service";
import { TariffsService } from "@/services/tariffs.service";
import { StorageTagger } from "@/services/storageTagger.service";
import CookieService from "@/services/cookies/CookieService";
import ContactFormService from "@/services/wordpress/contact-form.service";
import CoverageService from "@/services/coverage/coverage.service";
import AddressService from "@/services/address/address.service";
import CreateWebOrderRequestBuilderFactory from "@/services/webapi/factory/CreateWebOrderRequestBuilderFactory";
import CreateCoverageQueryRequestBuilderFactory from "@/services/webapi/factory/CreateCoverageQueryRequestBuilderFactory";
import RecaptchaService from "@/services/recaptcha.service";
import EhubService from "@/services/ehub/EhubService";
import LeadInfoService from "@/services/webapi/LeadInfoService";

const isStaging = window.environment === "staging";
const isProd = window.environment === "production";
const isLocal = !isStaging && !isProd;

const simTariffService = new SimTariffService(
  isProd ? "https://kz.dream.poda.cz/kz/api" : "https://kz.staging.dream.poda.cz/kz/api",
  isProd ? "bcb7c255c9529fe2286b89697f837d38" : "826e9a6509b1f1d7d17beb9fbe938239"
);
const webApiService = new WebApiService(!isLocal ? "/api/v1/web" : "https://www-test.poda.cz/api/v1/web");
const gtmService = new GTMService();
const utmService = new UtmService();
const cookieService = new CookieService();
const ehubService = new EhubService(cookieService);
ehubService.initialize();
const leadInfoService = new LeadInfoService(utmService, ehubService);
const localStorageTagger = new StorageTagger(window.localStorage, "poda_psi");
const sessionStorageTagger = new StorageTagger(window.sessionStorage, "poda_ssi");
const createCoverageQueryRequestBuilderFactory = new CreateCoverageQueryRequestBuilderFactory(
  leadInfoService,
  cookieService,
  localStorageTagger,
  sessionStorageTagger
);
const recaptchaService = new RecaptchaService("6Ld1IVMiAAAAAMGFu9nwMVq2QbNRpUpOXC8jLfv7");
const exponeaService = new ExponeaService();
const coverageService = new CoverageService(
  webApiService,
  gtmService,
  exponeaService,
  createCoverageQueryRequestBuilderFactory,
  recaptchaService,
  !isLocal ? "/api/v1/coverage" : "https://www-test.poda.cz/api/v1/coverage"
);
const addressService = new AddressService(
  !isLocal ? "/api/v1/search/address" : "https://www-test.poda.cz/api/v1/search/address"
);
const tariffsService = new TariffsService(
  utmService,
  localStorageTagger,
  sessionStorageTagger,
  coverageService,
  addressService
);
const contactFormService = new ContactFormService();
const createWebOrderRequestBuilderFactory = new CreateWebOrderRequestBuilderFactory(
  leadInfoService,
  cookieService,
  localStorageTagger,
  sessionStorageTagger
);

export interface State {
  simTariffService: SimTariffService;
  tariffsService: TariffsService;
  webApiService: WebApiService;
  exponeaService: ExponeaService;
  gtmService: GTMService;
  utmService: UtmService;
  localStorageTagger: StorageTagger;
  sessionStorageTagger: StorageTagger;
  cookieService: CookieService;
  contactFormService: ContactFormService;
  coverageService: CoverageService;
  addressService: AddressService;
  createWebOrderRequestBuilderFactory: CreateWebOrderRequestBuilderFactory;
  recaptchaService: RecaptchaService;
  ehubService: EhubService;
  leadInfoService: LeadInfoService;
}
const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
  state: {
    simTariffService: simTariffService,
    webApiService: webApiService,
    exponeaService: exponeaService,
    gtmService: gtmService,
    tariffsService: tariffsService,
    utmService: utmService,
    localStorageTagger: localStorageTagger,
    sessionStorageTagger: sessionStorageTagger,
    cookieService: cookieService,
    contactFormService: contactFormService,
    coverageService: coverageService,
    addressService: addressService,
    createWebOrderRequestBuilderFactory: createWebOrderRequestBuilderFactory,
    recaptchaService: recaptchaService,
    ehubService: ehubService,
    leadInfoService: leadInfoService,
  },
  getters: {
    simTariffService(state: State): SimTariffService {
      return state.simTariffService;
    },
    tariffsService(state: State): TariffsService {
      return state.tariffsService;
    },
    webApiService(state: State): WebApiService {
      return state.webApiService;
    },
    exponeaService(state: State): ExponeaService {
      return state.exponeaService;
    },
    gtmService(state: State): GTMService {
      return state.gtmService;
    },
    utmService(state: State): UtmService {
      return state.utmService;
    },
    localStorageTagger(state: State): StorageTagger {
      return state.localStorageTagger;
    },
    sessionStorageTagger(state: State): StorageTagger {
      return state.sessionStorageTagger;
    },
    cookieService(state: State): CookieService {
      return state.cookieService;
    },
    contactFormService(state: State): ContactFormService {
      return state.contactFormService;
    },
    coverageService(state: State): CoverageService {
      return state.coverageService;
    },
    addressService(state: State): AddressService {
      return state.addressService;
    },
    createWebOrderRequestBuilderFactory(state: State): CreateWebOrderRequestBuilderFactory {
      return state.createWebOrderRequestBuilderFactory;
    },
    recaptchaService(state: State): RecaptchaService {
      return state.recaptchaService;
    },
    ehubService(state: State): EhubService {
      return state.ehubService;
    },
    leadInfoService(state: State): LeadInfoService {
      return state.leadInfoService;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };

// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
  interface Store<S> {
    direct: AppStore;
  }
}
