import type { SmsVerificationInitRequest } from "@/services/sim-tariffs/request/SmsVerificationInitRequest";
import type { SmsVerificationConfirmRequest } from "@/services/sim-tariffs/request/SmsVerificationConfirmRequest";
import type { SmsVerificationConfirmResponse } from "@/services/sim-tariffs/response/SmsVerificationConfirmResponse";
import type { CreateOrderRequest } from "@/services/sim-tariffs/request/CreateOrderRequest";
import type { CreateOrderResponse } from "@/services/sim-tariffs/response/CreateOrderResponse";
import type { SmsVerificationInitResponse } from "@/services/sim-tariffs/response/SmsVerificationInitResponse";
import type { SimTariffGroup } from "@/services/sim-tariffs/SimTariffGroup";
import type { SimTariffProgram } from "@/services/sim-tariffs/SimTariffProgram";
import * as Sentry from "@sentry/vue";

export default class SimTariffService {
  protected intraApiUrl = "";
  protected intraApiKey = "";

  constructor(intraApiUrl: string, intraApiKey: string) {
    this.intraApiUrl = intraApiUrl;
    this.intraApiKey = intraApiKey;
  }

  protected groupsCache: SimTariffGroup[] = [];
  protected programsCache: SimTariffProgram[] = [];

  protected async loadGroupsAndPrograms(): Promise<void> {
    const options = {
      method: "GET",
      headers: {
        api_key: this.intraApiKey,
      },
    };

    await fetch(this.intraApiUrl + "/mobiles/programs", options)
      .then((response) => response.json())
      .then((response) => {
        this.groupsCache = response.data.groups;
        this.programsCache = response.data.programs;
      })
      .catch((error) => Sentry.captureException(error));
  }

  async smsVerificationInit(req: SmsVerificationInitRequest): Promise<SmsVerificationInitResponse> {
    const options = {
      method: "POST",
      headers: {
        "api-key": this.intraApiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    const res = await fetch(this.intraApiUrl + "/user/sms_login", options);
    const resJson = await res.json();
    resJson.code = res.status;
    return resJson;
  }

  async smsVerificationConfirm(req: SmsVerificationConfirmRequest): Promise<SmsVerificationConfirmResponse> {
    const options = {
      method: "POST",
      headers: {
        "api-key": this.intraApiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    const res = await fetch(this.intraApiUrl + "/user/sms_login", options);
    const resJson = await res.json();
    resJson.code = res.status;
    return resJson;
  }

  async createOrder(req: CreateOrderRequest): Promise<CreateOrderResponse> {
    const options = {
      method: "POST",
      headers: {
        "api-key": this.intraApiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req),
    };

    const res = await fetch(this.intraApiUrl + "/mobiles/", options);
    const resJson = await res.json();
    resJson.code = res.status;
    return resJson;
  }

  async getGroups() {
    if (this.groupsCache.length === 0) {
      await this.loadGroupsAndPrograms();
    }
    return this.groupsCache;
  }
  async getPrograms(onlyIds: number[] = []): Promise<SimTariffProgram[]> {
    if (this.programsCache.length === 0) {
      await this.loadGroupsAndPrograms();
    }
    if (onlyIds.length > 0) {
      const res = [];
      for (const id of onlyIds) {
        res.push(this.programsCache[id]);
      }
      return res;
    }
    return this.programsCache;
  }

  async getProgram(id: number) {
    return this.programsCache[id];
  }
}
