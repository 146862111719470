export default class CookieService {
  protected config = {
    mode: "opt-in", // 'opt-in', 'opt-out'
    current_lang: "en",
    autorun: true, // run as soon as loaded
    page_scripts: true,
    hide_from_bots: true,
    cookie_name: "cc_cookie",
    cookie_expiration: 365, // default: 12 months (in days)
    cookie_domain: window.location.hostname, // default: current domain
    cookie_path: "/",
    cookie_same_site: "Lax",
    use_rfc_cookie: false,
    autoclear_cookies: true,
    revision: 0,
    script_selector: "data-cookiecategory",
  };

  /**
   * Set cookie, by specifying name and value
   * @param {string} name
   * @param {string} value
   * @param {number|null} expirationDays
   */
  setCookie(name: string, value: string, expirationDays: number | null = null) {
    const cookie_expiration = expirationDays ?? this.config.cookie_expiration;

    value = this.config.use_rfc_cookie ? encodeURIComponent(value) : value;

    const date = new Date();
    date.setTime(date.getTime() + 1000 * (cookie_expiration * 24 * 60 * 60));
    const expires = "; expires=" + date.toUTCString();

    let cookieStr = name + "=" + (value || "") + expires + "; Path=" + this.config.cookie_path + ";";
    cookieStr += " SameSite=" + this.config.cookie_same_site + ";";

    // assures cookie works with localhost (=> don't specify domain if on localhost)
    if (window.location.hostname.indexOf(".") > -1) {
      cookieStr += " Domain=" + this.config.cookie_domain + ";";
    }

    if (window.location.protocol === "https:") {
      cookieStr += " Secure;";
    }

    document.cookie = cookieStr;

    //_log("CookieConsent [SET_COOKIE]: cookie '" + name + "'=", JSON.parse(value));
    //_log("CookieConsent [SET_COOKIE]: '" + name + "' expires after " + cookie_expiration + " day(s)");
  }

  /**
   * Get cookie value by name,
   * returns the cookie value if found (or an array
   * of cookies if filter provided), otherwise empty string: ""
   * @param {string} name
   * @param {string} filter 'one' or 'all'
   * @param {boolean} [get_value] set to true to obtain its value
   * @returns {string|string[]}
   */
  getCookie(name: string, filter = "one", get_value = true) {
    let found;

    if (filter === "one") {
      found = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
      found = found ? (get_value ? found.pop() : name) : "";

      if (found && name === this.config.cookie_name) {
        try {
          found = JSON.parse(found);
        } catch (e) {
          try {
            found = JSON.parse(decodeURIComponent(found));
          } catch (e) {
            // if I got here => cookie value is not a valid json string
            found = {};
          }
        }
        found = JSON.stringify(found);
      }
    } else if (filter === "all") {
      // array of names of all existing cookies
      const cookies = document.cookie.split(/;\s*/);
      found = [];
      for (let i = 0; i < cookies.length; i++) {
        found.push(cookies[i].split("=")[0]);
      }
    }
    return found;
  }

  getCookieOne(name: string): string {
    const res = this.getCookie(name);
    return typeof res === "string" ? res : res[0];
  }
}
