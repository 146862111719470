import { reactive } from "vue";

/**
 * This service relies heavily on js/poda/exponea.js being called BEFORE this service is used. The Exponea Init.
 * snippet is 1) autogenerated, 2) not TS friendly => has been extracted to that separate file.
 */
export class ExponeaService {
  protected isProd = false;
  protected alreadyTrackedEvts: Set<string> = new Set<string>();

  constructor() {
    this.isProd = !(window.location.hostname.indexOf("localhost") > -1);
  }

  async identify(payload: object): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (exponea) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      exponea.identify(payload);
    } else {
      console.error("ExponeaService: Identification attempted without initialized SDK", [payload]);
    }
  }

  async track(evtName: string, evt: object): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (exponea) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      exponea.track(evtName, evt);
    } else {
      console.error("ExponeaService: Event tracking attempted without initialized SDK", [evtName, evt]);
    }
  }

  /**
   * Will discard all tracking requests if an event with evtName already tracked during the session. This protection
   * will NOT have effect on tracking done directly via track method.
   * @param evtName
   * @param evt
   */
  async trackOncePerSession(evtName: string, evt: object): Promise<void> {
    // Load mem of already tracked evts
    this.alreadyTrackedEvts = new Set(JSON.parse(window.sessionStorage.getItem("exponeaService.alreadyTrackedEvts")));
    if (this.alreadyTrackedEvts.has(evtName)) {
      return; // Evt with same key already tracked
    }
    // Log the event
    this.alreadyTrackedEvts.add(evtName);
    window.sessionStorage.setItem(
      "exponeaService.alreadyTrackedEvts",
      JSON.stringify(Array.from(this.alreadyTrackedEvts))
    );
    // Track
    return this.track(evtName, evt);
  }

  /**
   * Tracked once per (session, idadr). Tracked once per session - but if idadr changes, track anyway.
   */
  public async trackCoverageQuery(idadr: string, evt: object): Promise<void> {
    const lastTrackedIdadr = window.sessionStorage.getItem("exponeaService.trackCoverageQuery.idadr");
    if (lastTrackedIdadr === null || parseInt(lastTrackedIdadr) !== parseInt(idadr)) {
      window.sessionStorage.setItem("exponeaService.trackCoverageQuery.idadr", idadr);
      return this.track("coverage_query", evt);
    }
  }
}

export const exponeaService = reactive(new ExponeaService());
